import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';
import CaseStudiesList from '../components/CaseStudy/CaseStudiesList';

const sections = [
  {
    img: '/pillars/iot.png',
    text: [
      {
        title: 'Let IoT solutions work for you',
        description:
          'The Internet of Things gives you and your company new opportunities to improve business processes,\n' +
          ' introduce new consumer services. Logistics, Industry 4.0, Smart Home Services, Telemedicine, IoT in\n' +
          ' Sport, regardless of the IoT segment you need reliable software. Hardware, firmware, middleware,\n' +
          ' end-user applications all these elements need to match, and SolveQ can be your reliable partner in\n' +
          ' software development integration of the solution.',
      },
    ],
  },
  {
    img: '',
    cta: {
      text: 'Contact us',
      link: '/contact',
    },
    text: [
      {
        title: '',
        description: `<p>With proven experience in designing and developing entire solutions centered around 
a physical device, and promoting user interactions through our knowledge of what makes users truly engage with 
a platform, we've managed to change the way users perform day-to-day tasks. Our team includes mobile development 
and backend experts, allowing us to build every part of a solution and tailor every part for end-user engagement.</p>`,
      },
    ],
  },
];

const CaseStudyPage = () => (
  <>
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">Internet of things</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[0]} />
    <CaseStudiesList caseStudyTags={['iot']} />
    {/* <ArticleMiddle /> */}
    {/* <UseCaseList /> */}
    {/* <ArticleBottom/> */}
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock imgFull  {...sections[1]} />
  </>
);

export default CaseStudyPage;
